p a {
  color: #0000ee;
  text-decoration: underline;
}

/* 標題樣式 */
h1,
h2,
h3 {
  letter-spacing: 1px;
  font-weight: bold;
  margin: 0.83em auto;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}
